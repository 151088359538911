import {StylesParamsType} from '../../types/createStylesParams'
import {
  buttonDefaultStylesParams,
  buttonHoverStylesParams,
  ButtonDefaultStylesParams,
  ButtonHoverStylesParams,
} from '../../components-shared/Settings/Button/stylesParams'
import {
  linkStylesParams,
  LinkStylesParams,
} from '../../components-shared/Settings/Link/stylesParams'
import {
  formBoxStylesParams,
  formLineStylesParams,
  FormBoxStylesParams,
  FormLineStylesParams,
} from '../../components-shared/Settings/FormDesign/stylesParams'

import {layoutStylesParams, LayoutStylesParams} from './Settings/Tabs/Layout/stylesParams'
import {generalStylesParams, GeneralStylesParams} from './Settings/Tabs/Design/General/stylesParams'
import {
  timeSlotsDefaultStylesParams,
  timeSlotsSelectedStylesParams,
  TimeSlotsDefaultStylesParams,
  TimeSlotsSelectedStylesParams,
} from './Settings/Tabs/Design/TimeSlots/stylesParams'
import {mobileStylesParams, MobileStylesParams} from './Settings.mobile/stylesParams'

type DesktopStylesParams = LayoutStylesParams &
  GeneralStylesParams &
  FormBoxStylesParams &
  FormLineStylesParams &
  TimeSlotsDefaultStylesParams &
  TimeSlotsSelectedStylesParams &
  ButtonDefaultStylesParams &
  ButtonHoverStylesParams &
  LinkStylesParams

export type StylesParams = DesktopStylesParams & MobileStylesParams

export const stylesParams: StylesParamsType<StylesParams> = {
  ...layoutStylesParams,
  ...generalStylesParams,
  ...formBoxStylesParams,
  ...formLineStylesParams,
  ...timeSlotsDefaultStylesParams,
  ...timeSlotsSelectedStylesParams,
  ...buttonDefaultStylesParams,
  ...buttonHoverStylesParams,
  ...linkStylesParams,
  ...mobileStylesParams,
}

export default stylesParams
