import {createSettingsParams, SettingsParamType} from '@wix/tpa-settings'

import {ButtonAlignment, FormLayout, TextAlignment} from '../../../../../types/createStylesParams'

export type LayoutSettingsParams = {
  showTitle: SettingsParamType.Boolean
  showDescription: SettingsParamType.Boolean
  textAlignment: SettingsParamType.String
  formLayout: SettingsParamType.String
  buttonAlignment: SettingsParamType.String
}

export const layoutSettingsParams = createSettingsParams<LayoutSettingsParams>({
  showTitle: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  showDescription: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  textAlignment: {
    type: SettingsParamType.String,
    getDefaultValue: () => TextAlignment.Center,
  },
  formLayout: {
    type: SettingsParamType.String,
    getDefaultValue: () => FormLayout.Strip,
  },
  buttonAlignment: {
    type: SettingsParamType.String,
    getDefaultValue: () => ButtonAlignment.Center,
  },
})
