import {StyleParamType, createStylesParams} from '@wix/tpa-settings'

export type LayoutStylesParams = {
  spaceBetweenTitleAndDescription: StyleParamType.Number
  spaceBetweenTextAndForm: StyleParamType.Number
  spaceBetweenFormAndTimeSlots: StyleParamType.Number
  sidePadding: StyleParamType.Number
  topBottomPadding: StyleParamType.Number
  buttonPadding: StyleParamType.Number
}

export const layoutStylesParams = createStylesParams<LayoutStylesParams>({
  spaceBetweenTitleAndDescription: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  spaceBetweenTextAndForm: {
    type: StyleParamType.Number,
    getDefaultValue: () => 40,
  },
  spaceBetweenFormAndTimeSlots: {
    type: StyleParamType.Number,
    getDefaultValue: () => 80,
  },
  sidePadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 40,
  },
  topBottomPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 40,
  },
  buttonPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
})
