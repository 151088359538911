import React, {FC} from 'react'
import noop from 'lodash/noop'
import {StatesButton, StatesButtonStates, ThreeDotsLoader} from 'wix-ui-tpa'
import {useEnvironment} from '@wix/yoshi-flow-editor'

import {classes, st} from './ReserveButton.st.css'
import {reserveButtonDataHooks} from './constants'

interface Props {
  isLoading: boolean
  label: string
  width?: number
}

export const ReserveButton: FC<Props> = ({isLoading, label, width}) => {
  const {isMobile} = useEnvironment()

  return (
    <StatesButton
      type="submit"
      state={isLoading ? StatesButtonStates.IN_PROGRESS : StatesButtonStates.IDLE}
      idleContent={label}
      inProgressContent={<ThreeDotsLoader className={classes.loader} />}
      className={st(classes.button, {mobile: isMobile})}
      data-hook={reserveButtonDataHooks.button()}
      style={{
        ...(width ? {width: `${width}px`} : {}),
      }}
      // glitch in WUT. It always takes onClick as a prop despite type="submit" on button
      onClick={noop}
    />
  )
}
